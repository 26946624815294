// Tracking helper functions
// https://vistaprint.atlassian.net/wiki/spaces/GA/pages/2218560827/Navigation+Tracking+Framework
// For specifics on parameter values

import {
  getTracking,
  trackingCountry,
  trackingLanguage,
  genTrack,
} from './trackingWrapper';

import {
  TrackingPageSection,
  TrackingPageStage,
  TrackingAction,
  TrackingPageLocation,
  PageAnalyticsData,
  TrackingConfiguration,
  TrackingPageDept,
  TrackingActionLabel,
  TrackingCategory,
  AddToCartPriceModel,
} from './trackingModels';
import { LoggerInterface } from '@vp/digital-logging-lib';

import {
  ExperienceAndOrderingDetails,
  Mpv,
} from '@vp/digital-merchandising-site-experience-lib';
import { AddSubscriptionProductParams } from '@vp/digital-cart-lib';

// Tracks that a page was viewed
export function trackPageViewed(
  page: PageAnalyticsData,
  overrideParams?: object
): void {
  const tracking = getTracking();
  if (!tracking) {
    return;
  }

  tracking.page(page?.pageName, {
    pageName: page?.pageName,
    pageSection: page?.pageSection,
    pageStage: page?.pageStage,
    ...(page?.pageDept && { pageDept: page?.pageDept }),
    genTrack,
    language: trackingLanguage,
    locale: trackingCountry,
    ...overrideParams,
  });
}

// Tracks that a Product Page was viewed
export function trackProductPageViewed(
  page: PageAnalyticsData,
  overrideParams?: object
): void {
  trackPageViewed(page, overrideParams);
}

// Tracks that a Product was viewed
export function trackProductViewed(
  page: PageAnalyticsData,
  overrideParams?: object
): void {
  const tracking = getTracking();
  if (!tracking) {
    return;
  }

  tracking.track(TrackingAction.PRODUCT_VIEWED, {
    label: TrackingActionLabel.PRODUCT_VIEWED,
    pageSection: page?.pageSection,
    pageStage: page?.pageStage,
    pageName: page?.pageName,
    product_id: page?.mpvId,
    ...(page?.pageDept && { pageDept: page?.pageDept }),
    name: page?.productName, // Note: This is the product name, not an MPVID
    ...(page?.productKey && { core_product_id: page?.productKey }),
    ...(page?.variant && { variant: page?.variant }),
    ...(page?.brand && { brand: page?.brand }),
    ...overrideParams,
  });
}

// Tracks that a Card was viewed in a workspace
export function trackCardViewed(
  page: PageAnalyticsData,
  label: string,
  product_id?: string,
  overrideParams?: object
): void {
  const tracking = getTracking();
  if (!tracking) {
    return;
  }

  tracking.track(TrackingAction.WORKSPACE_CARD_DISPLAYED, {
    label: label,
    pageSection: page?.pageSection,
    pageStage: page?.pageStage,
    pageName: page?.pageName,
    product_id: product_id,
    ...(page?.pageDept && { pageDept: page?.pageDept }),
    name: page?.productName, // Note: This is the product name, not an MPVID
    ...(page?.productKey && { core_product_id: page?.productKey }),
    ...(page?.variant && { variant: page?.variant }),
    ...(page?.brand && { brand: page?.brand }),
    ...overrideParams,
  });
}

// Tracks that a Product List was viewed
export function trackProductListViewed(
  page: PageAnalyticsData,
  productListCount: number,
  variant: Array<string>,
  overrideParams?: object,
  overrideParamsProduct?: object,
  hideProductProps: boolean = false
): void {
  const tracking = getTracking();
  if (!tracking) {
    return;
  }

  const product = {
    product_id: page?.mpvId,
    name: page?.productName ?? page?.mpvId,
    ...(page?.productKey && { core_product_id: page?.productKey }),
    core_product_version: '13',
    ...(page?.pageSectionID && { list_section_id: page?.pageSectionID }),
    ...overrideParamsProduct,
  };

  const products: Array<any> = [];

  for (let i = 0; i < productListCount; i++) {
    products[i] = {
      ...product,
      position: i + 1,
      variant: variant[i],
    };
  }
  const trackingProperties = {
    label: TrackingAction.PRODUCT_LIST_VIEWED,
    list_id: page?.pageSection,
    pageSection: page?.pageSection,
    pageStage: page?.pageStage,
    pageName: page?.pageName,
    product_id: page?.mpvId,
    ...(page?.pageDept && { pageDept: page?.pageDept }),
    name: page?.productName, // Note: This is the product name, not an MPVID
    ...(page?.productKey && { core_product_id: page?.productKey }), // the PRD ID also known as Product key
    core_product_version: '13',
    ...(page?.brand && { brand: page?.brand }),
    ...overrideParams,
    products: products,
  };
  // product_id and core_product_version must be hidden in some cases
  if (hideProductProps) {
    delete trackingProperties.product_id;
    delete trackingProperties.core_product_version;
  }
  tracking.track(TrackingAction.PRODUCT_LIST_VIEWED, trackingProperties);
}

// A V2 version of the trackProductListViewed to address new changes
export function trackProductListViewedV2(
  page: PageAnalyticsData,
  productListCount: number,
  variant: Array<string>,
  overrideParams?: object,
  overrideParamsProduct?: object,
  hideProductProps: boolean = false
): void {
  const tracking = getTracking();
  if (!tracking) {
    return;
  }

  const product = {
    product_id: page?.mpvId,
    name: page?.productName ?? page?.mpvId,
    ...(page?.productKey && { core_product_id: page?.productKey }),
    list_section_id: page?.pageSectionID,
    ...overrideParamsProduct,
  };

  const products: Array<any> = [];

  for (let i = 0; i < productListCount; i++) {
    products[i] = {
      ...product,
      position: i + 1,
      variant: variant[i],
    };
  }

  const trackingProperties = {
    label: TrackingAction.PRODUCT_LIST_VIEWED,
    pageSection: page?.pageSection,
    pageStage: page?.pageStage,
    pageName: page?.pageName,
    ...(page?.pageDept && { pageDept: page?.pageDept }),
    name: page?.productName, // Note: This is the product name, not an MPVID
    ...(page?.productKey && { core_product_id: page?.productKey }),
    ...(page?.brand && { brand: page?.brand }),
    ...overrideParams,
    products: products,
  };

  if (hideProductProps) {
    delete trackingProperties.core_product_id;
  }

  tracking.track(TrackingAction.PRODUCT_LIST_VIEWED, trackingProperties);
}
export function trackProductAdded(
  page: PageAnalyticsData,
  pageLocation: TrackingPageLocation,
  overrideParams?: object
): void {
  const tracking = getTracking();
  if (!tracking) {
    return;
  }

  tracking.track(TrackingAction.PRODUCT_ADDED, {
    label: TrackingAction.PRODUCT_ADDED,
    pageSection: TrackingPageSection.PRODUCT_PAGE,
    pageStage: TrackingPageStage.DISCOVER,
    pageName: page?.pageName,
    pageLocation,
    product_id: page?.mpvId,
    name: page?.productName, // Note: This is the product name, not an MPVID
    ...overrideParams,
  });
}

export function trackPlansAdded(
  page: PageAnalyticsData,
  overrideParams?: object
): void {
  const tracking = getTracking();
  if (!tracking) {
    return;
  }

  tracking.track(TrackingAction.PRODUCT_ADDED, {
    label: TrackingAction.PRODUCT_ADDED,
    pageSection: TrackingPageSection.PRODUCT_PAGE,
    pageStage: TrackingPageStage.DISCOVER,
    pageName: page?.pageName,
    core_product_id: page?.productKey,
    product_id: page?.mpvId,
    name: page?.productName, // Note: This is the product name, not an MPVID
    ...overrideParams,
  });
}

// Function that tracks both a Product Page load, as well as the Product Viewed event
export function trackProductPageLoad(
  page: PageAnalyticsData,
  overrideParams?: object
): void {
  trackProductPageViewed(page, overrideParams);
  trackProductViewed(page, overrideParams);
}

//branded types
// see https://basarat.gitbook.io/typescript/main-1/nominaltyping
enum EventDetailEnum {
  _ = '',
}
export type EventDetail = string & EventDetailEnum;

enum NavigationDetailEnum {
  _ = '',
}
export type NavigationDetail = string & NavigationDetailEnum;

// Function that tracks events (somewhat generic)
// Note that specific parameters can different slightly, see related docs at:
// https://vistaprint.atlassian.net/wiki/spaces/GA/pages/100337503/Clicks+interactions+tracking+framework
// https://vistaprint.atlassian.net/wiki/spaces/GA/pages/137858254/Digital+Products+interactions+tracking
export function trackEventBase(
  action: string,
  label: string,
  eventDetail: string,
  navigationDetail: string,
  pageSection: TrackingPageSection,
  pageStage: TrackingPageStage,
  pageName: string,
  category: TrackingPageSection | string,
  mpvId?: string,
  productKey?: string,
  pageDept?: TrackingPageDept,
  overrideParams?: object
): void {
  const tracking = getTracking();
  if (!tracking) {
    return;
  }

  tracking.track(action, {
    label,
    eventDetail,
    ...(navigationDetail && { navigationDetail }), // conditionally added navigationDetail only if present
    pageSection,
    pageStage,
    pageName,
    ...(category && { category }), // conditionally added category only if present
    ...(mpvId && { product_id: mpvId }),
    ...(productKey && { core_product_id: productKey }),
    ...(pageDept && { pageDept: pageDept }),
    ...overrideParams,
  });
}

// Tracks events that aren't navigation events
export function trackEvent(
  action: string,
  label: string,
  eventDetail: EventDetail | string,
  pageSection: TrackingPageSection,
  pageStage: TrackingPageStage,
  pageName: string,
  category: TrackingPageSection | string,
  mpvId?: string,
  productKey?: string,
  pageDept?: TrackingPageDept,
  overrideParams?: object
): void {
  const tracking = getTracking();
  if (!tracking) {
    return;
  }

  trackEventBase(
    action,
    label,
    eventDetail,
    makeSimpleNavigationDetail(''),
    pageSection,
    pageStage,
    pageName,
    category,
    mpvId,
    productKey,
    pageDept,
    overrideParams
  );
}

// Function that tracks navigation events
// Note that specific parameters can different slightly, see related docs at:
// https://vistaprint.atlassian.net/wiki/spaces/GA/pages/2218560827/Navigation+Tracking+Framework
export function trackNavigationEvent(
  action: string,
  label: string,
  sourcePage: PageAnalyticsData,
  targetPage: PageAnalyticsData,
  pageZone: string,
  ctaDetails: string = '',
  overrideParams?: object
): void {
  const eventDetail: EventDetail = makeEventDetail(
    sourcePage?.pageUri,
    targetPage?.pageUri,
    pageZone,
    ctaDetails
  );
  const navigationDetail: NavigationDetail = makeNavigationDetail(ctaDetails);

  trackEventBase(
    action,
    label,
    eventDetail,
    navigationDetail,
    sourcePage?.pageSection,
    sourcePage?.pageStage,
    sourcePage?.pageName,
    sourcePage?.pageSection, // category value is same as pageSection
    undefined, // no mpvid in this case
    undefined, // no productkey in this case
    sourcePage?.pageDept,
    overrideParams
  );
}

// Track Experiment Clicked Event
export function trackExperimentClickedEvent(
  sourcePage: PageAnalyticsData,
  targetPage: PageAnalyticsData,
  pageZone: string,
  ctaDetails: string = '',
  trackingTenant?: string,
  experimentName?: string,
  variationName?: string,
  experimentDetail?: string,
  overrideParams?: object
): void {
  const tracking = getTracking();
  if (!tracking) {
    return;
  }

  const eventDetail: EventDetail = makeEventDetail(
    sourcePage?.pageUri,
    targetPage?.pageUri,
    pageZone,
    ctaDetails
  );

  const navigationDetail: NavigationDetail =
    makeExperimentClickedDetail(ctaDetails);
  const experimentViewLabel = sourcePage?.pageSection + ' view';
  tracking.track(TrackingAction.EXPERIMENT_CLICKED, {
    label: experimentViewLabel,
    eventDetail: eventDetail,
    navigationDetail: navigationDetail,
    pageSection: sourcePage?.pageSection,
    pageStage: sourcePage?.pageStage,
    pageName: sourcePage?.pageName,
    trackingTenant: trackingTenant,
    experimentName: experimentName,
    variationName: variationName,
    experimentDetail: experimentDetail,
    product_id: sourcePage?.mpvId,
    ...(sourcePage?.pageDept && { pageDept: sourcePage?.pageDept }),
    name: sourcePage?.productName, // Note: This is the product name, not an MPVID
    ...(sourcePage?.productKey && { core_product_id: sourcePage?.productKey }),
    ...(sourcePage?.variant && { variant: sourcePage?.variant }),
    ...(sourcePage?.brand && { brand: sourcePage?.brand }),
    ...overrideParams,
  });
}

// Track Experiment Viewed Event
export function trackExperimentViewedEvent(
  sourcePage: PageAnalyticsData,
  trackingTenant?: string,
  experimentName?: string,
  variationName?: string,
  experimentDetail?: string,
  overrideParams?: object
): void {
  const tracking = getTracking();
  if (!tracking) {
    return;
  }

  const experimentViewLabel = sourcePage?.pageSection + ' view';
  tracking.track(TrackingAction.EXPERIMENT_VIEWED, {
    label: experimentViewLabel,
    pageSection: sourcePage?.pageSection,
    pageStage: sourcePage?.pageStage,
    pageName: sourcePage?.pageName,
    trackingTenant: trackingTenant,
    experimentName: experimentName,
    variationName: variationName,
    experimentDetail: experimentDetail,
    product_id: sourcePage?.mpvId,
    ...(sourcePage?.pageDept && { pageDept: sourcePage?.pageDept }),
    name: sourcePage?.productName, // Note: This is the product name, not an MPVID
    ...(sourcePage?.productKey && { core_product_id: sourcePage?.productKey }),
    ...(sourcePage?.variant && { variant: sourcePage?.variant }),
    ...(sourcePage?.brand && { brand: sourcePage?.brand }),
    ...overrideParams,
  });
}

// Function to track promotion events, which takes a different set of parameters than
// the more "normal" tracking functions
export function trackPromotionEvent(
  action: string,
  promotionId: string,
  creative: string,
  name: string, // This is usually the MPV id
  position: string, // Similar to the zone, where the interaction happened
  pageSection: TrackingPageSection,
  pageStage: TrackingPageStage,
  pageName: string,
  overrideParams?: object
): void {
  const tracking = getTracking();
  if (!tracking) {
    return;
  }

  tracking.track(action, {
    promotion_id: promotionId,
    creative,
    name,
    position,
    pageSection,
    pageStage,
    pageName,
    ...overrideParams,
  });
}

// Takes in parameters to create the event detail string as defined here:
// https://vistaprint.atlassian.net/wiki/spaces/GA/pages/2218560827/Navigation+Tracking+Framework
export function makeEventDetail(
  sourcePageURI: string,
  destinationPageURI: string,
  pageZone: string,
  ctaDetails?: string
): EventDetail {
  // eslint-disable-next-line prefer-rest-params
  const retval: EventDetail = Array.prototype.join.call(arguments, ';');
  return retval;
}

// Make an eventDetail out of one string, for special cases (like domain)
export function makeSimpleEventDetail(eventDetail: string): EventDetail {
  const retval: EventDetail = eventDetail as EventDetail;
  return retval;
}

// Takes in parameters to create the navigation detail string as defined here:
// https://vistaprint.atlassian.net/wiki/spaces/GA/pages/2218560827/Navigation+Tracking+Framework
export function makeNavigationDetail(ctaDetails?: string): NavigationDetail {
  // eslint-disable-next-line prefer-rest-params
  const retval: NavigationDetail = Array.prototype.join.call(arguments, ';');
  return retval;
}

export function makeExperimentClickedDetail(
  ctaDetails?: string
): NavigationDetail {
  // eslint-disable-next-line prefer-rest-params
  const retval: NavigationDetail = Array.prototype.join.call(arguments, ';');
  return retval;
}

// Make an eventDetail out of one string, for special cases (like domain)
export function makeSimpleNavigationDetail(
  navigationDetail: string
): NavigationDetail {
  const retval: NavigationDetail = navigationDetail as NavigationDetail;
  return retval;
}

export function getTrackingConfiguration(
  page: PageAnalyticsData
): TrackingConfiguration {
  const trackingConfigData: TrackingConfiguration = {
    pageName: page?.pageName,
    pageSection: page?.pageSection,
    pageStage: page?.pageStage,
  };
  return trackingConfigData;
}

// Tracks that a Domain was searched or selected based on the action
export function trackDomainEvent(
  label: string,
  action: TrackingAction,
  eventDetail: EventDetail | string,
  page: PageAnalyticsData,
  overrideParams?: object
): void {
  const tracking = getTracking();
  if (!tracking) {
    return;
  }

  trackEvent(
    action,
    label,
    eventDetail,
    page.pageSection,
    page.pageStage,
    page.pageName,
    page.category,
    page.mpvId,
    page.productKey,
    page.pageDept,
    overrideParams
  );
}

// Tracks that a Pop Up Event
export function trackPopUpEvent(
  label: string,
  action: TrackingAction,
  eventDetail: EventDetail | string,
  page: PageAnalyticsData,
  overrideParams?: object
): void {
  const tracking = getTracking();
  if (!tracking) {
    return;
  }

  trackEvent(
    action,
    label,
    eventDetail,
    page.pageSection,
    page.pageStage,
    page.pageName,
    page.category,
    page.mpvId,
    page.productKey,
    page.pageDept,
    overrideParams
  );
}

export function trackToggleEvent(
  label: string,
  action: TrackingAction,
  eventDetail: EventDetail | string,
  page: PageAnalyticsData,
  overrideParams?: object
): void {
  const tracking = getTracking();
  if (!tracking) {
    return;
  }

  const { pageSection, pageStage, pageName, category, productKey, pageDept } =
    page;

  tracking.track(action, {
    label,
    eventDetail,
    pageSection,
    pageStage,
    pageName,
    category,
    ...(productKey && { core_product_id: productKey }),
    ...(pageDept && { pageDept }),
    ...overrideParams,
  });
}
export function trackPlanChosenEvent(
  label: string,
  action: TrackingAction,
  eventDetail: EventDetail | string,
  page: PageAnalyticsData,
  overrideParams?: object
): void {
  const tracking = getTracking();
  if (!tracking) {
    return;
  }

  const { pageSection, pageStage, pageName, category, productKey, pageDept } =
    page;

  tracking.track(action, {
    label,
    eventDetail,
    pageSection,
    pageStage,
    pageName,
    category,
    ...(productKey && { core_product_id: productKey }),
    ...(pageDept && { pageDept }),
    ...overrideParams,
  });
}
/**
 * @param {object}  selectedAttributes - An object with string key and value like:
 * {'KEY1': 'VALUE1', 'KEY2": 'VALUE2'}
 * @returns {string} [KEY1]:[VALUE1]\_[KEY2]:[VALUE2]_...[KEYn]:[VALUEn]
 */
export const getAddToCartPriceModelVariant = (
  selectedAttributes: any
): string => {
  let variant = '';
  const keys = Object.keys(selectedAttributes);
  keys.forEach((element, index) => {
    variant += element + ':' + selectedAttributes[element];
    if (index < keys.length - 1) {
      variant += '_';
    }
  });
  return variant;
};

/**
 * @param {PageAnalyticsData}  pageAnalyticsData - The page Analytics data where the event will be fired.
 * @param {Mpv}  mpv - The MPV model of the product.
 * @param {AddSubscriptionProductParams}  params - The params that will be added to the cart.
 * @param {AddToCartPriceModel}  price - Contains the informations related to the prices of the product ,
 * @param {LoggerInterface}  logger - The logger
 * @returns {void}
 */
export const fireProductAddedEvent = (
  pageAnalyticsData: PageAnalyticsData,
  mpv: Mpv,
  params: AddSubscriptionProductParams,
  price: AddToCartPriceModel,
  logger: LoggerInterface
) => {
  const action = TrackingAction.PRODUCT_ADDED;
  logger.info('fireProductAdded: ', { params, mpv, price });

  // add the variant attribute from the mpv if not included in AddToCartPriceModel
  if (!price.variant) {
    price.variant = '';
    mpv.experienceAndOrderingDetails.forEach(
      (element: ExperienceAndOrderingDetails, index: number) => {
        price.variant = `${element.key}:${element.value}`;
        if (index < mpv.experienceAndOrderingDetails.length) {
          price.variant += '_';
        }
      }
    );
  }
  trackEvent(
    action,
    action,
    '',
    pageAnalyticsData.pageSection,
    pageAnalyticsData.pageStage,
    pageAnalyticsData.pageName,
    pageAnalyticsData.category,
    pageAnalyticsData.mpvId,
    mpv.coreProductId,
    pageAnalyticsData.pageDept,
    {
      ...price,
    }
  );
};
